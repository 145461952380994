import { Box, Card, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { CustomTable } from '../../components/CustomTable';
import { HeadCell } from '../../components/CustomTable/CustomTable';
import { CSVUploadDialog } from '../../components/dialogs/CSVUploadConfirmationDialog';
import AlertErrors from '../../components/ReviewCSVLinks/AlertErrors';
import ReviewCSVLinksScreenTitle from '../../components/ReviewCSVLinks/ReviewCSVLinksScreenTitle';
import values from '../../data/values';
import { useParseCSV } from '../../hooks/useParseCSV';
import { createLinksWhitCSV, fetchLinks, fetchOneUseLinks } from '../../store/action_creators/links.actions';
import { LinkTypeEnum, Status } from '../../store/config/enums';
import { CreateLinksWithCSVRequest, RootState } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';

export interface RowData {
  email: string;
  description: string;
  reference: string;
  validUntil: string;
  currency: string;
  amount: string;
}

interface Error {
  field: string;
  message: string;
}

export interface ErrorRowData {
  row: RowData;
  errors: Error[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCard: {
      marginTop: '1.25rem',
      padding: '1.5625rem 1.25rem',
    },
    titleTexts: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      fontWeight: +styles.semiBoldTextWeight,
      color: styles.slateBlue,
      fontSize: '1.0625rem',
      marginBottom: '1.1875rem',
    },
    linkCount: {
      fontWeight: +styles.boldTextWeight,
      color: styles.slateBlue,
      fontSize: '0.875rem',
      marginBottom: '1.1875rem',
    },
  }),
);

const columns: HeadCell<RowData>[] = [
  { columnId: 'email', label: 'Email' },
  { columnId: 'description', label: 'Descripción' },
  { columnId: 'reference', label: 'Referencia' },
  { columnId: 'validUntil', label: 'Válido hasta' },
  { columnId: 'currency', label: 'Moneda' },
  { columnId: 'amount', label: 'Importe' },
];

function ReviewCSVLinks() {
  const location = useLocation();
  const file = location.state?.file || null;
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { links, auth } = useSelector((state: RootState) => state);
  const [uploading, setUploading] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const { csvData, csvErrors, fileWithoutHeaders } = useParseCSV(file);
  const linkCount = csvData.length;
  const errorCount = csvErrors.length;
  const businessId = auth?.account?.business?.id;

  const handleSubmit = async () => {
    setUploading(true);
    const uploadLinksCSVRequest: CreateLinksWithCSVRequest = {
      file: fileWithoutHeaders!,
    };
    await Promise.resolve(dispatch(createLinksWhitCSV(uploadLinksCSVRequest)));
    dispatch(
      fetchOneUseLinks(
        links.oneUseLinksFilters,
        1,
        values.linksPageLimit,
        businessId!,
        LinkTypeEnum.ONETIME,
        true,
      ),
    );
    dispatch(
      fetchLinks(
        links.linksFilters,
        1,
        values.linksPageLimit,
        businessId!,
        LinkTypeEnum.ALL,
        true,
        true,
        true,
      ),
    );
  };

  const closeSnack = () => {
    setUploading(false);
    if (links.createLinksWithCSVSuccess) {
      navigate('/one-use-links');
    }
  };

  const openDialog = (type: string) => {
    setOpen(true);
    setDialogType(type);
  };

  return (
    <div className="screen-container">
      <ReviewCSVLinksScreenTitle
        openDialog={openDialog}
        linkCount={linkCount}
        errorCount={errorCount}
        uploading={uploading}
      />
      {errorCount > 0 && <AlertErrors columns={columns} csvErrors={csvErrors} errorCount={errorCount} />}

      <Card className={classes.tableCard}>
        <Box className={classes.titleTexts}>
          <Typography className={classes.title}>Links de 1 uso</Typography>
          <Typography className={classes.linkCount}>Total de links: {linkCount}</Typography>
        </Box>
        <CustomTable headCells={columns} rowsData={csvData} emptyDataMessage="El archivo no contiene links" />
      </Card>

      <CSVUploadDialog
        open={open}
        setOpen={setOpen}
        dialogType={dialogType}
        setDialogType={setDialogType}
        action={handleSubmit}
        uploading={uploading}
      />
      <CustomSnackbar
        open={uploading && (links.createLinksWithCSVSuccess || links.createLinksWithCSVErrorMessage !== null)}
        message={
          links.createLinksWithCSVSuccess
            ? 'Links creados correctamente'
            : 'Ocurrió un error al crear los links'
        }
        handleClose={closeSnack}
        type={links.createLinksWithCSVSuccess ? Status.SUCCESS : Status.ERROR}
      />
    </div>
  );
}

export default ReviewCSVLinks;
