import { Box, Card, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import ErrorsTable, { HeadCell } from '../../components/ReviewCSVLinks/ErrorsTable';
import { ErrorRowData, RowData } from '../../screens/UploadCSVLinks/ReviewCSVLinks';
import styles from '../../styles/_variables.module.scss';
import { AlertTriangle } from '../Icons/AlertTriangle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warnings: {
      padding: '1.2rem',
      borderRadius: '0.4rem',
      border: `1px solid ${styles.yellowWarningBorder}`,
      backgroundColor: `${styles.yellowWarningBackground}`,
      marginBottom: '1rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.5625rem',

      '& svg': {
        width: '1.375rem',
        height: '1.375rem',
        '& path': {
          fill: `${styles.warnings}`,
        },
      },
    },
    warningText: {
      color: styles.yellowWarningText,
      fontSize: '0.75rem',
      fontWeight: +styles.normalTextWeight,
    },
    tableCard: {
      marginTop: '1.25rem',
      padding: '1.5625rem 1.25rem',
    },
    titleTexts: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      fontWeight: +styles.semiBoldTextWeight,
      color: styles.slateBlue,
      fontSize: '1.0625rem',
      marginBottom: '1.1875rem',
    },
    linkCount: {
      fontWeight: +styles.boldTextWeight,
      color: styles.slateBlue,
      fontSize: '0.875rem',
      marginBottom: '1.1875rem',
    },
  }),
);

interface AlertErrorsProps {
  columns: HeadCell<RowData>[];
  csvErrors: ErrorRowData[];
  errorCount: number;
}

export default function AlertErrors({ columns, csvErrors, errorCount }: AlertErrorsProps) {
  const classes = useStyles();

  return (
    <Card className={classes.tableCard}>
      <div className={classes.warnings}>
        <AlertTriangle />
        <Typography variant="subtitle2" className={classes.warningText}>
          Los links que presenten errores no podrán ser creados. Puedes corregirlos ajustando los datos
          ingresados y cargando nuevamente el archivo CSV actualizado.
        </Typography>
      </div>
      <>
        <Box className={classes.titleTexts}>
          <Typography className={classes.title}>Links con error</Typography>
          <Typography className={classes.linkCount}>Total de links con error: {errorCount}</Typography>
        </Box>
        <ErrorsTable headCells={columns} invalidRows={csvErrors} />
      </>
    </Card>
  );
}
