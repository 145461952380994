import Papa from 'papaparse';
import { useState } from 'react';

interface ValidationResult {
  error: string | null;
  selectedFile: File | null;
}

export const useValidationCSV = () => {
  const [validationResult, setValidationResult] = useState<ValidationResult>({
    error: null,
    selectedFile: null,
  });

  const validateFile = (file: File) => {
    if (file.size > 1 * 1024 * 1024) {
      setValidationResult({
        error:
          '*El archivo supera el tamaño máximo permitido de 1 MB. Por favor, suba un archivo más pequeño.',
        selectedFile: null,
      });
      return;
    }

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const fileHeaders = results.meta.fields || [];
        const expectedHeaders = ['Importe', 'Moneda', 'Descripción', 'Referencia', 'Email', 'Valido hasta'];

        if (results.meta.delimiter !== ';') {
          setValidationResult({
            error:
              '*El archivo CSV no utiliza el delimitador ";". Verifique la configuración de exportación y vuelva a intentarlo.',
            selectedFile: null,
          });
          return;
        }

        const missingHeaders = expectedHeaders.filter((header) => !fileHeaders.includes(header));
        const moreHeaders = expectedHeaders.length < fileHeaders.length;

        if (moreHeaders) {
          setValidationResult({
            error: '*El archivo CSV contiene columnas extra, Por favor, verifique y vuelva a intentarlo.',
            selectedFile: null,
          });
          return;
        }

        if (missingHeaders.length > 0) {
          setValidationResult({
            error: `*El archivo CSV no contiene los encabezados esperados: ${missingHeaders.join(
              ', ',
            )}. Por favor, verifique y vuelva a intentarlo.`,
            selectedFile: null,
          });
          return;
        }

        setValidationResult({
          error: null,
          selectedFile: file,
        });
      },
    });
  };

  return { validationResult, validateFile };
};
