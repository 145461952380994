import { Box, Button, Card, createStyles, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom';
import { csvExample } from '../../assets/examples/csvExample';
import UploadIcon from '../../assets/UploadIcon';
import CheckCircleIcon from '../../components/Icons/CheckCircleIcon';
import { CsvFile } from '../../components/Icons/CsvFile';
import { ScreenTitle } from '../../components/ScreenTitle';
import { csvLinksHeaders } from '../../data/csv';
import { useValidationCSV } from '../../hooks/useValidationCSV';
import styles from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadCard: {
      marginTop: '1.25rem',
      padding: '1.5625rem 1.25rem',
    },
    title: {
      fontWeight: +styles.semiBoldTextWeight,
      color: styles.slateBlue,
      fontSize: '1.0625rem',
      marginBottom: '1.1875rem',
    },
    uploadContainer: {
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'space-between',
      gap: '1.1875rem',
      marginBottom: '0.5rem',
    },
    fileContainer: {
      width: '80%',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      border: `1px dashed ${styles.borderButtonSecondary}`,
      '& p': {
        marginTop: '0.9375rem',
      },
    },
    icon: {
      width: '2.1875rem',
      height: '2.1875rem',
    },
    helperText: {
      color: styles.slateBlue,
      fontSize: '0.75rem',
      width: '32%',
      textAlign: 'center',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: 'auto',
    },
    download: {
      marginTop: '1.125rem',
      cursor: 'pointer',
      color: styles.primary,
      textDecoration: 'underline',
    },
  }),
);

function UploadCSVLinks() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { validationResult, validateFile } = useValidationCSV();
  const [hasClickedDownloadCSV, setHasClickedDownloadCSV] = useState<boolean>(false);
  const csvRef = useRef<any>();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      validateFile(file);
    }
  };

  const handleUploadClick = () => {
    document.getElementById('csvFileInput')?.click();
  };

  const handleContinue = () => {
    navigate('/review-csv-links', { state: { file: validationResult.selectedFile } });
  };

  const handleCancel = () => {
    navigate('/one-use-links');
  };

  const buildCSVPayments = () => {
    return csvExample;
  };

  useEffect(() => {
    if (hasClickedDownloadCSV) {
      csvRef.current.link.click();
      setHasClickedDownloadCSV(false);
    }
  }, [hasClickedDownloadCSV]);

  return (
    <div className="screen-container">
      <ScreenTitle title={'Crear links con CSV'} step={1}>
        <Button onClick={handleCancel} variant="outlined" color="secondary">
          Cancelar
        </Button>
        <Button
          onClick={handleContinue}
          variant="contained"
          color="primary"
          disabled={!validationResult.selectedFile}
        >
          Continuar
        </Button>
      </ScreenTitle>
      <Card className={classes.uploadCard}>
        <Typography className={classes.title}>Archivo con Links</Typography>
        <Box className={classes.uploadContainer}>
          <Box className={classes.fileContainer}>
            {validationResult.selectedFile ? (
              <CheckCircleIcon className={classes.icon} />
            ) : (
              <CsvFile className={classes.icon} />
            )}
            <Typography className={classes.helperText}>
              {validationResult.selectedFile
                ? `El archivo ${validationResult.selectedFile.name} ha sido cargado correctamente`
                : `El archivo debe estar en formato CSV, utilizar el delimitador “;” y no superar 1 MB de tamaño.`}
            </Typography>
          </Box>
          <Box className={classes.buttonContainer}>
            <Button
              onClick={handleUploadClick}
              variant="outlined"
              color="secondary"
              startIcon={<UploadIcon />}
            >
              <input
                id="csvFileInput"
                type="file"
                accept=".csv"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              Cargar CSV
            </Button>
            <Link onClick={() => setHasClickedDownloadCSV(true)} className={classes.download}>
              Descargar csv de ejemplo
            </Link>
            <CSVLink
              data={buildCSVPayments()}
              filename="Links.csv"
              headers={csvLinksHeaders}
              ref={csvRef}
              separator=";"
              enclosingCharacter=""
            />
          </Box>
        </Box>
        {validationResult.error && (
          <Typography variant="body2" color="error">
            {validationResult.error}
          </Typography>
        )}
      </Card>
    </div>
  );
}

export default UploadCSVLinks;
