import Papa from 'papaparse';
import { useEffect, useState } from 'react';
import { ValidationError } from 'yup';

import schemas from '../data/schemas';
import { currencyHelper } from '../helpers/currencyHelper';
import { RowData } from '../screens/UploadCSVLinks/ReviewCSVLinks';

export interface ErrorRowData {
  row: RowData;
  errors: { field: string; message: string }[];
}

export const useParseCSV = (file: File | null) => {
  const [csvData, setCSVData] = useState<RowData[]>([]);
  const [csvErrors, setCsvErrors] = useState<ErrorRowData[]>([]);
  const [fileWithoutHeaders, setFileWithoutHeaders] = useState<File | null>(null);

  useEffect(() => {
    if (file) {
      Papa.parse(file, {
        header: false,
        skipEmptyLines: true,
        complete: async (results) => {
          const { data } = results as { data: string[][] };

          const validRows: RowData[] = [];
          const invalidRows: ErrorRowData[] = [];

          const columnNames = ['amount', 'currency', 'description', 'reference', 'email', 'validUntil'];

          const removedHeader = data.slice(1);

          for (const row of removedHeader) {
            const rowObject: RowData = columnNames.reduce((acc, colName, index) => {
              acc[colName as keyof RowData] = row[index];
              return acc;
            }, {} as RowData);

            try {
              await schemas.CsvLinkSchema.validate(rowObject, { abortEarly: false });
              validRows.push(rowObject);
            } catch (validationError: unknown) {
              if (validationError instanceof ValidationError) {
                const errors = validationError.inner.map((err: ValidationError) => {
                  return {
                    field: err.path || '',
                    message: err.message,
                  };
                });
                invalidRows.push({
                  row: {
                    amount: rowObject.amount,
                    currency: currencyHelper.getCurrencyName(+rowObject.currency) || rowObject.currency,
                    description: rowObject.description,
                    reference: rowObject.reference,
                    email: rowObject.email || '-',
                    validUntil: rowObject.validUntil || '-',
                  },
                  errors,
                });
              }
            }
          }

          const mappedCSVData = validRows.map((row: RowData) => {
            return {
              amount: row.amount,
              currency: currencyHelper.getCurrencyName(+row.currency) || row.currency,
              description: row.description,
              reference: row.reference,
              email: row.email || '-',
              validUntil: row.validUntil || '-',
            };
          });
          const newCSVContent = Papa.unparse(removedHeader, { delimiter: ';' });
          const newFile = new File([newCSVContent], file.name, {
            type: 'text/csv',
          });

          setCSVData(mappedCSVData);
          setCsvErrors(invalidRows);
          setFileWithoutHeaders(newFile);
        },
      });
    }
  }, [file]);

  return { csvData, csvErrors, fileWithoutHeaders };
};
