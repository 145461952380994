import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { QuestionMark } from '../Icons/QuestionMark';

const cellStyles: React.CSSProperties = {
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
};

const iconStyles: React.CSSProperties = {
  marginLeft: '0.5rem',
  display: 'inline-flex',
  alignItems: 'center',
};

export interface CustomTableProps<T> {
  headCells: HeadCell<T>[];
  invalidRows: { row: T; errors: { field: string; message: string }[] }[];
}

export interface HeadCell<T> {
  columnId: keyof T;
  label: string;
}

const ErrorsTable = <T extends object>({ headCells, invalidRows }: CustomTableProps<T>) => {
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map(({ label }, index) => (
                <TableCell
                  key={index}
                  style={{
                    minWidth: !label ? '3rem' : undefined,
                  }}
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {invalidRows.map((invalidRow, rowIndex) => {
              const row = invalidRow.row;
              const rowErrors = invalidRow.errors;

              return (
                <TableRow key={rowIndex}>
                  {headCells.map(({ columnId }, cellIndex) => {
                    const error = rowErrors.find((err) => err.field === columnId);

                    return (
                      <TableCell key={`${rowIndex}-${cellIndex}`} align="center">
                        <div style={{ display: 'inline-flex', alignItems: 'center', maxWidth: '100%' }}>
                          <Typography style={cellStyles}>
                            {row[columnId] !== '' ? row[columnId] : '-'}
                          </Typography>
                          {error && (
                            <Tooltip title={error.message} arrow>
                              <div style={iconStyles}>
                                <QuestionMark />
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ErrorsTable;
