import { filteringHelper } from '../helpers/filteringHelper';
import { LinkTypeEnum } from '../store/config/enums';
import {
  CreateLinkRequest,
  CreateLinksWithCSVRequest,
  CreateSplitLinkRequest,
  DeleteLinkRequest,
  FetchLinksRequest,
  Link,
  LinkDataResponse,
  LinkToPay,
  LinksResponse,
  ShareLinkRequest,
  UpdateLinkRequest,
  UpdateSplitLinkRequest,
} from '../store/config/types';
import { get, httpDelete, post, postForm, put } from './base.service';
import { endpoints } from './endpoints';

export const linksService = {
  fetchLinks,
  createLink,
  createSplitLink,
  updateLink,
  updateSplitLink,
  deleteLink,
  shareLink,
  getLinkData,
  validateLink,
  uploadLinksCSV,
};

async function fetchLinks(
  request: FetchLinksRequest,
  businessId: number,
  type: LinkTypeEnum,
): Promise<LinksResponse | undefined> {
  const filterString = filteringHelper.getFiltersForLinks(request, type);

  return await get<LinksResponse>(
    `${endpoints['links']}/${businessId}?page=${request.page}&limit=${request.count}${filterString}`,
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function createLink(linkRequest: CreateLinkRequest): Promise<Link | undefined> {
  return await post<Link>(`${endpoints['links']}`, linkRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function createSplitLink(linkRequest: CreateSplitLinkRequest): Promise<Link | undefined> {
  return await post<Link>(`${endpoints['links']}/splitLink`, linkRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function updateLink(
  linkRequest: UpdateLinkRequest,
  linkId: string,
  businessId: string,
): Promise<Link | undefined> {
  return await put<Link>(`${endpoints['links']}/${businessId}/${linkId}`, linkRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function updateSplitLink(
  linkRequest: UpdateSplitLinkRequest,
  linkId: string,
  businessId: string,
): Promise<Link | undefined> {
  return await put<Link>(`${endpoints['links']}/splitLink/${linkId}`, linkRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function deleteLink({ businessId, linkId }: DeleteLinkRequest): Promise<boolean | undefined> {
  return await httpDelete<boolean>(`${endpoints['links']}/${businessId}/${linkId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function shareLink({ linkId, email }: ShareLinkRequest): Promise<boolean | undefined> {
  return await post<boolean>(`${endpoints['links']}/share/${linkId}`, { email })
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function getLinkData(linkId: string): Promise<LinkDataResponse | undefined> {
  return await get<LinkDataResponse>(`${endpoints['links']}/data/${linkId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function validateLink(linkId: string): Promise<LinkToPay | undefined> {
  return await get<LinkToPay>(`${endpoints['links']}/validate/${linkId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function uploadLinksCSV(
  createLinksWhitCSVRequest: CreateLinksWithCSVRequest,
): Promise<Link[] | undefined> {
  const formData = new FormData();
  formData.append('file', createLinksWhitCSVRequest.file);

  return postForm<Link[]>(`${endpoints['csv']}`, formData)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}
