export const csvExample = [
  {
    amount: 100,
    currency: 1,
    description: 'Descripción 1',
    reference: 'Referencia 1',
    email: 'links@links.com.uy',
    validUntil: null,
  },
  {
    amount: 258,
    currency: 2,
    description: 'Descripción 2',
    reference: 'Referencia 2',
    email: 'plexo@links.com.uy',
    validUntil: '10/11/2030',
  },
];
