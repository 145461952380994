import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useNavigate } from 'react-router-dom';
import styles from '../../../styles/_variables.module.scss';
import { Loading } from '../../Loading';

const useDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      paddingBottom: 0,
    },
    icon: {
      cursor: 'pointer',
      userSelect: 'none',
      color: theme.palette.grey[500],
    },
    titleText: {
      fontWeight: Number(styles.semiBoldTextWeight),
      fontSize: '1.0625rem',
    },
    messageText: {
      marginTop: '0.5625rem',
      fontSize: '0.875rem',
      color: styles.slateBlue,
      fontWeight: Number(styles.regularTextWeight),
    },
    content: {
      padding: '2rem 3rem',
      paddingTop: '1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    actions: {
      marginTop: '1.4rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    okButton: {
      marginLeft: '1rem',
    },
    errorButton: {
      marginLeft: '1rem',
      backgroundColor: theme.palette.error.main,
      color: styles.white,
      border: `1px solid ${theme.palette.error.main}`,

      '&:hover': {
        boxShadow: 'none',
        backgroundColor: styles.white,
        color: theme.palette.error.main,
        border: `1px solid ${theme.palette.error.main}`,
      },
    },
  }),
);

interface CSVUploadDialogProps {
  open: boolean;
  setOpen(value: boolean): void;
  dialogType: string;
  setDialogType(value: string): void;
  action(): void;
  uploading: boolean;
}

function CSVUploadDialog({
  open,
  setOpen,
  dialogType,
  setDialogType,
  action,
  uploading,
}: CSVUploadDialogProps) {
  const navigate = useNavigate();
  const classes = useDialogStyles();
  const message = uploading
    ? 'Puede demorar unos segundos. Por favor no salgas del producto.'
    : dialogType === 'ok'
    ? 'Una vez que confirmes no podrás revertir esta acción'
    : 'Una vez que confirmes perderás la información ingresada.';
  const title = uploading
    ? 'Estamos procesando tu solicitud'
    : dialogType === 'ok'
    ? '¿Estas seguro que deseas crear estos links?'
    : '¿Estás seguro de que no deseas crear estos links?';
  const okText = dialogType === 'ok' ? 'Confirmar' : 'No crear links';
  const okAction = dialogType === 'ok' ? action : () => navigate('/upload-csv-links');
  const buttonClass = dialogType === 'ok' ? classes.okButton : classes.errorButton;

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <Dialog className="dialog" open={open} onClose={closeDialog}>
      <DialogTitle className={classes.title}>
        <CloseIcon fontSize="default" className={classes.icon} onClick={closeDialog} />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography className={classes.titleText}>{title}</Typography>
        <Typography className={classes.messageText}>{message}</Typography>
        {uploading ? (
          <Loading height="8rem" width="8rem" />
        ) : (
          <div className={classes.actions}>
            <Button onClick={closeDialog} variant="outlined" color="secondary">
              Volver
            </Button>
            <Button color="primary" className={buttonClass} variant="contained" onClick={okAction}>
              {okText}
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default CSVUploadDialog;
