import { Button, CircularProgress, Divider } from '@material-ui/core';
import { ScreenTitle } from '../ScreenTitle';

interface ReviewCSVLinksScreenTitleProps {
  errorCount: number;
  linkCount: number;
  uploading: boolean;
  openDialog: (type: string) => void;
}

export default function ReviewCSVLinksScreenTitle({
  errorCount,
  linkCount,
  uploading,
  openDialog,
}: ReviewCSVLinksScreenTitleProps) {
  return (
    <ScreenTitle title={'Crear links con CSV'} step={2}>
      <Button onClick={() => openDialog('cancel')} variant="outlined" color="secondary">
        Cancelar
      </Button>
      <Divider orientation="vertical" flexItem />
      <Button onClick={() => openDialog('cancel')} variant="outlined" color="secondary">
        Volver
      </Button>
      <Button
        onClick={() => openDialog('ok')}
        disabled={errorCount > 0 || uploading || !linkCount}
        variant="contained"
        color="primary"
      >
        {!uploading ? 'Continuar' : <CircularProgress size={24} color="inherit" />}
      </Button>
    </ScreenTitle>
  );
}
