import {
  BusinessIssuer,
  CreatePaymentMethodRequest,
  DesiredIssuersRequest,
  Issuer,
  PaymentMethod,
  UpdatePaymentMethodRequest,
} from '../store/config/types';
import { get, httpDelete, post, put } from './base.service';
import { endpoints } from './endpoints';

export const paymentMethodsService = {
  fetchPaymentMethods,
  fetchPossibleInstallments,
  createPaymentMethod,
  updatePaymentMethod,
  deletePaymentMethod,
  fetchIssuers,
  fetchBusinessIssuers,
  sendDesiredIssuers,
};

async function fetchPaymentMethods(businessId: number): Promise<PaymentMethod[] | undefined> {
  return await get<PaymentMethod[]>(`${endpoints['issuers']}/configuration/user/${businessId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function fetchPossibleInstallments(): Promise<number[] | undefined> {
  return await get<number[]>(endpoints['installments'])
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function createPaymentMethod(
  createPaymentMethodRequest: CreatePaymentMethodRequest,
): Promise<PaymentMethod | undefined> {
  return await post<PaymentMethod>(`${endpoints['issuers']}/configuration`, createPaymentMethodRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function updatePaymentMethod(
  updatePaymentMethodRequest: UpdatePaymentMethodRequest,
): Promise<PaymentMethod | undefined> {
  return await put<PaymentMethod>(
    `${endpoints['issuers']}/configuration/${updatePaymentMethodRequest.businessId}/${updatePaymentMethodRequest.issuerId}`,
    updatePaymentMethodRequest,
  )
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function deletePaymentMethod(
  businessId: number,
  issuerId: number,
  subBusinessId?: string,
): Promise<boolean | undefined> {
  return await httpDelete<boolean>(`${endpoints['issuers']}/configuration/${businessId}/${issuerId}`, {
    subBusinessId: subBusinessId || null,
  })
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function fetchIssuers(): Promise<Issuer[] | undefined> {
  return await get<Issuer[]>(`${endpoints['issuers']}/issuersPaymentProcessors`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function fetchBusinessIssuers(businessId: number): Promise<BusinessIssuer[] | undefined> {
  return await get<BusinessIssuer[]>(`${endpoints['issuers']}/configuration/${businessId}`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw new Error(error);
    });
}

async function sendDesiredIssuers(
  businessId: number,
  issuers: DesiredIssuersRequest,
): Promise<boolean | undefined> {
  return await post<boolean>(`${endpoints['issuers']}/${businessId}`, issuers.issuers)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}
