import { Dispatch } from 'redux';
import { linksService } from '../../services/links.service';
import {
  ADD_DASHBOARD_LINKS,
  ADD_LINKS,
  ADD_ONE_USE_LINKS,
  CREATE_LINKS_WITH_CSV_FAILED,
  CREATE_LINKS_WITH_CSV_SUCCESS,
  CREATE_LINK_FAILED,
  CREATE_LINK_SUCCESS,
  CREATING_LINK,
  CREATING_LINKS_WITH_CSV,
  CreateLinkTypes,
  CreateLinkWithCSVTypes,
  DELETE_LINK_FAILED,
  DELETE_LINK_SUCCESS,
  DELETING_LINK,
  DeleteLinkTypes,
  FETCHING_LINKS,
  FETCHING_ONE_USE_LINKS,
  FETCH_LINKS_FAILED,
  FETCH_ONE_USE_LINKS_FAILED,
  FetchLinksTypes,
  FetchOneUseLinksTypes,
  GETTING_LINK_DATA,
  GET_LINK_DATA_FAILED,
  GET_LINK_DATA_SUCCESS,
  GetLinkDataTypes,
  LOAD_LINK_FILTERS,
  LOAD_ONE_USE_LINK_FILTERS,
  LoadLinksFiltersAction,
  LoadOneUseLinksFiltersAction,
  SHARE_LINK_FAILED,
  SHARE_LINK_SUCCESS,
  SHARING_LINK,
  ShareLinkTypes,
  UPDATE_LINK_FAILED,
  UPDATE_LINK_SUCCESS,
  UPDATING_LINK,
  UpdateLinkTypes,
  VALIDATE_LINK_FAILED,
  VALIDATE_LINK_SUCCESS,
  VALIDATING_LINK,
  ValidateLinkTypes,
} from '../config/ActionTypes';
import { LinkTypeEnum, PaymentTypeEnum } from '../config/enums';
import {
  CreateLinkRequest,
  CreateLinksWithCSVRequest,
  CreateSplitLinkRequest,
  DeleteLinkRequest,
  Filters,
  Link,
  LinkDataResponse,
  LinkToPay,
  LinksResponse,
  ShareLinkRequest,
  UpdateLinkRequest,
  UpdateSplitLinkRequest,
} from '../config/types';

//#region Fetch links

export const fetchLinks = (
  filters: Filters,
  page: number,
  count: number,
  businessId: number,
  type: LinkTypeEnum,
  reset: boolean,
  onlyActive?: boolean,
  isDashboard?: boolean,
) => {
  return (dispatch: Dispatch<FetchLinksTypes>) => {
    dispatch(loadingLinks(type, reset));

    return linksService.fetchLinks({ ...filters, page, count, onlyActive }, businessId, type).then(
      (response) => {
        if (isDashboard) {
          dispatch(addDashboardLinks(response!));
        } else {
          dispatch(addLinks(response!));
        }
      },
      (error) => {
        dispatch(linksFailed(error));
      },
    );
  };
};

export const loadingLinks = (kind: LinkTypeEnum, reset: boolean): FetchLinksTypes => ({
  type: FETCHING_LINKS,
  kind,
  reset,
});

export const addLinks = (links: LinksResponse): FetchLinksTypes => ({
  type: ADD_LINKS,
  links,
});

export const addDashboardLinks = (links: LinksResponse): FetchLinksTypes => ({
  type: ADD_DASHBOARD_LINKS,
  links,
});

export const linksFailed = (error: string): FetchLinksTypes => ({
  type: FETCH_LINKS_FAILED,
  error,
});

//#endregion

//#region Fetch one use links

export const fetchOneUseLinks = (
  filters: Filters,
  page: number,
  count: number,
  businessId: number,
  type: LinkTypeEnum,
  reset: boolean,
) => {
  return (dispatch: Dispatch<FetchOneUseLinksTypes>) => {
    dispatch(loadingOneUseLinks(type, reset));

    return linksService.fetchLinks({ ...filters, page, count }, businessId, type).then(
      (response) => {
        dispatch(addOneUseLinks(response!));
      },
      (error) => {
        dispatch(oneUselinksFailed(error));
      },
    );
  };
};

export const loadingOneUseLinks = (kind: LinkTypeEnum, reset: boolean): FetchOneUseLinksTypes => ({
  type: FETCHING_ONE_USE_LINKS,
  kind,
  reset,
});

export const addOneUseLinks = (links: LinksResponse): FetchOneUseLinksTypes => ({
  type: ADD_ONE_USE_LINKS,
  links,
});

export const oneUselinksFailed = (error: string): FetchOneUseLinksTypes => ({
  type: FETCH_ONE_USE_LINKS_FAILED,
  error,
});

//#endregion

//#region Load filters

export const loadLinksFilters = (
  filters: Filters,
  page: number,
  count: number,
  businessId: number,
  type: LinkTypeEnum,
) => {
  return (dispatch: Dispatch<FetchLinksTypes | LoadLinksFiltersAction>) => {
    dispatch(loadFilters(filters));

    return linksService
      .fetchLinks(
        {
          page,
          count,
          ...filters,
        },
        businessId,
        type,
      )
      .then(
        (response) => {
          dispatch(addLinks(response!));
        },
        (error) => {
          dispatch(linksFailed(error));
        },
      );
  };
};

export const loadFilters = (filters: Filters): LoadLinksFiltersAction => ({
  type: LOAD_LINK_FILTERS,
  filters: filters,
});

//#endregion

//#region Load One Use filters

export const loadOneUseLinksFilters = (
  filters: Filters,
  page: number,
  count: number,
  businessId: number,
  type: LinkTypeEnum,
) => {
  return (dispatch: Dispatch<FetchOneUseLinksTypes | LoadOneUseLinksFiltersAction>) => {
    dispatch(loadOneUSeFilters(filters));

    return linksService
      .fetchLinks(
        {
          page,
          count,
          ...filters,
        },
        businessId,
        type,
      )
      .then(
        (response) => {
          dispatch(addOneUseLinks(response!));
        },
        (error) => {
          dispatch(oneUselinksFailed(error));
        },
      );
  };
};

export const loadOneUSeFilters = (filters: Filters): LoadOneUseLinksFiltersAction => ({
  type: LOAD_ONE_USE_LINK_FILTERS,
  filters: filters,
});

//#endregion

//#region Create link

export const createLink = (linkRequest: CreateLinkRequest | CreateSplitLinkRequest) => {
  return (dispatch: Dispatch<CreateLinkTypes>) => {
    dispatch(creatingLink());

    if (linkRequest.paymentType === PaymentTypeEnum.Split) {
      return linksService.createSplitLink(linkRequest as CreateSplitLinkRequest).then(
        (response) => {
          dispatch(createLinkSuccess(response!));
        },
        (error) => {
          dispatch(createLinkFailed(error));
        },
      );
    } else {
      return linksService.createLink(linkRequest as CreateLinkRequest).then(
        (response) => {
          dispatch(createLinkSuccess(response!));
        },
        (error) => {
          dispatch(createLinkFailed(error));
        },
      );
    }
  };
};

export const creatingLink = (): CreateLinkTypes => ({
  type: CREATING_LINK,
});

export const createLinkSuccess = (link: Link): CreateLinkTypes => ({
  type: CREATE_LINK_SUCCESS,
  link,
});

export const createLinkFailed = (error: string): CreateLinkTypes => ({
  type: CREATE_LINK_FAILED,
  error,
});

//#endregion

//#region Create link

export const createLinksWhitCSV = (createLinksWhitCSVRequest: CreateLinksWithCSVRequest) => {
  return (dispatch: Dispatch<CreateLinkWithCSVTypes>) => {
    dispatch(creatingLinksWhitCSV());

    return linksService.uploadLinksCSV(createLinksWhitCSVRequest).then(
      (response) => {
        dispatch(createLinksWithCSVSuccess(response!));
      },
      (error) => {
        dispatch(createLinksWithCSVFailed(error));
      },
    );
  };
};

export const creatingLinksWhitCSV = (): CreateLinkWithCSVTypes => ({
  type: CREATING_LINKS_WITH_CSV,
});

export const createLinksWithCSVSuccess = (links: Link[]): CreateLinkWithCSVTypes => ({
  type: CREATE_LINKS_WITH_CSV_SUCCESS,
  links,
});

export const createLinksWithCSVFailed = (error: string): CreateLinkWithCSVTypes => ({
  type: CREATE_LINKS_WITH_CSV_FAILED,
  error,
});

//#endregion

//#region Update link

export const updateLink = (
  request: UpdateLinkRequest | UpdateSplitLinkRequest,
  linkId: string,
  businessId: string,
) => {
  return (dispatch: Dispatch<UpdateLinkTypes>) => {
    dispatch(updatingLink());

    if (request.paymentType === PaymentTypeEnum.Split) {
      return linksService.updateSplitLink(request as UpdateSplitLinkRequest, linkId, businessId).then(
        (response) => {
          dispatch(updateLinkSuccess(response!));
        },
        (error) => {
          dispatch(updateLinkFailed(error));
        },
      );
    } else {
      return linksService.updateLink(request as UpdateLinkRequest, linkId, businessId).then(
        (response) => {
          dispatch(updateLinkSuccess(response!));
        },
        (error) => {
          dispatch(updateLinkFailed(error));
        },
      );
    }
  };
};

export const updatingLink = (): UpdateLinkTypes => ({
  type: UPDATING_LINK,
});

export const updateLinkSuccess = (link: Link): UpdateLinkTypes => ({
  type: UPDATE_LINK_SUCCESS,
  link,
});

export const updateLinkFailed = (error: string): UpdateLinkTypes => ({
  type: UPDATE_LINK_FAILED,
  error,
});

//#endregion

//#region Delete link

export const deleteLink = ({ businessId, linkId }: DeleteLinkRequest) => {
  return (dispatch: Dispatch<DeleteLinkTypes>) => {
    dispatch(deletingLink());
    return linksService.deleteLink({ businessId, linkId }).then(
      (response) => {
        dispatch(deleteLinkSuccess(linkId));
      },
      (error) => {
        dispatch(deleteLinkFailed(error));
      },
    );
  };
};

export const deletingLink = (): DeleteLinkTypes => ({
  type: DELETING_LINK,
});

export const deleteLinkSuccess = (linkId: string): DeleteLinkTypes => ({
  type: DELETE_LINK_SUCCESS,
  linkId,
});

export const deleteLinkFailed = (error: string): DeleteLinkTypes => ({
  type: DELETE_LINK_FAILED,
  error,
});

//#endregion

//#region Share link

export const shareLink = (request: ShareLinkRequest) => {
  return (dispatch: Dispatch<ShareLinkTypes>) => {
    dispatch(sharingLink());

    return linksService.shareLink(request).then(
      (response) => {
        dispatch(shareLinkSuccess());
      },
      (error) => {
        dispatch(shareLinkFailed(error));
      },
    );
  };
};

export const sharingLink = (): ShareLinkTypes => ({
  type: SHARING_LINK,
});

export const shareLinkSuccess = (): ShareLinkTypes => ({
  type: SHARE_LINK_SUCCESS,
});

export const shareLinkFailed = (error: string): ShareLinkTypes => ({
  type: SHARE_LINK_FAILED,
  error,
});

//#endregion

//#region Get link data
export const getLinkData = (linkId: string) => {
  return (dispatch: Dispatch<GetLinkDataTypes>) => {
    dispatch(gettingLinkData());

    return linksService.getLinkData(linkId).then(
      (response) => {
        if (response) {
          dispatch(getLinkDataSuccess(response));
        }
      },
      (error) => {
        dispatch(getLinkDataFailed(error));
      },
    );
  };
};

export const gettingLinkData = (): GetLinkDataTypes => ({
  type: GETTING_LINK_DATA,
});

export const getLinkDataSuccess = (link: LinkDataResponse): GetLinkDataTypes => ({
  type: GET_LINK_DATA_SUCCESS,
  link,
});

export const getLinkDataFailed = (error: string): GetLinkDataTypes => ({
  type: GET_LINK_DATA_FAILED,
  error,
});

//#endregion

//#region Validate link

export const validateLink = (linkId: string) => {
  return (dispatch: Dispatch<ValidateLinkTypes>) => {
    dispatch(validatingLink());

    return linksService.validateLink(linkId).then(
      (response) => {
        if (response) {
          dispatch(validateLinkSuccess(response!));
        }
      },
      (error) => {
        dispatch(validateLinkFailed(error));
      },
    );
  };
};

export const validatingLink = (): ValidateLinkTypes => ({
  type: VALIDATING_LINK,
});

export const validateLinkSuccess = (link: LinkToPay): ValidateLinkTypes => ({
  type: VALIDATE_LINK_SUCCESS,
  link,
});

export const validateLinkFailed = (error: string): ValidateLinkTypes => ({
  type: VALIDATE_LINK_FAILED,
  error,
});

//#endregion
