interface CsvHeader {
  key: string;
  label: string;
}

export const csvHeaders: CsvHeader[] = [
  { label: 'Estado', key: 'status' },
  { label: 'Fecha', key: 'date' },
  { label: 'Descripción', key: 'description' },
  { label: 'Tipo de link', key: 'linkType' },
  { label: 'Medio de pago', key: 'issuerName' },
  { label: 'Últimos 4 dígitos', key: 'maskedPan' },
  { label: 'Tipo de tarjeta', key: 'cardType' },
  { label: 'Importe', key: 'amount' },
  { label: 'Cuotas', key: 'installments' },
  { label: 'Nro. Autorización', key: 'authorization' },
  { label: 'Ticket', key: 'ticket' },
  { label: 'Referencia link', key: 'referencePayment' },
  { label: 'Referencia usuario', key: 'clientComments' },
];

export const csvNotificationHeaders: CsvHeader[] = [
  { label: 'HTTP Status', key: 'httpCodeResponse' },
  { label: 'Fecha de la notificación', key: 'created' },
  { label: 'Id Plexo', key: 'plexoTransactionId' },
  { label: 'Link External Id', key: 'externalId' },
  { label: 'Cuotas', key: 'installments' },
  { label: 'Tarjeta', key: 'issuerName' },
  { label: 'Últimos 4 dígitos', key: 'maskedPan' },
  { label: 'Monto', key: 'value' },
  { label: 'Moneda', key: 'currency' },
  { label: 'Estado del pago', key: 'status' },
  { label: 'Fecha del pago', key: 'date' },
];

export const csvLinksHeaders: CsvHeader[] = [
  { label: 'Importe', key: 'amount' },
  { label: 'Moneda', key: 'currency' },
  { label: 'Descripción', key: 'description' },
  { label: 'Referencia', key: 'reference' },
  { label: 'Email', key: 'email' },
  { label: 'Valido hasta', key: 'validUntil' },
];
